import VisuallyHidden from "@reach/visually-hidden";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { CmsImage, StreamfieldBlock } from "interfaces";

import styles from "./value-props.module.scss";

export interface ValuePropsCmsData {
  heading: string;
  value_props: {
    image_block: CmsImage | null;
    text: string;
  }[];
}

export type ValuePropsBlock = StreamfieldBlock<"value_props", ValuePropsCmsData>;

export const ValueProps = ({ heading, value_props }: ValuePropsCmsData) => (
  <section className={styles.valueProps}>
    <VisuallyHidden>
      <h1>{heading}</h1>
    </VisuallyHidden>
    <ul className={styles.propsList}>
      {value_props.map((valueProp) => (
        <li key={valueProp.text.replace(" ", "")}>
          <div className={styles.valueProp}>
            {valueProp.image_block && (
              <CmsResponsiveImage
                cmsImage={valueProp.image_block}
                cldSrcSet={[80, 100, 160, 200, 240, 300]}
                sizes="(max-width: 767px) 80px, (min-width: 768px) 100px"
                cloudinaryProps={{
                  aspectRatio: "1:1",
                  crop: "fill",
                }}
                decorative={true}
              />
            )}
            <span className={styles.text}>{valueProp.text}</span>
          </div>
        </li>
      ))}
    </ul>
  </section>
);
