import { StreamfieldBlock } from "interfaces";
import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import { LargeImageBackgroundHeroCmsData } from "./LargeImageBackgroundHero";

import styles from "./hero-carousel.module.scss";

import { NextSlideButton, PreviousSlideButton } from "components/GenericCarousel";

export interface HeroCarouselCmsData<T> {
  slides: StreamfieldBlock<"slide", T>[];
}

interface Props<T> extends HeroCarouselCmsData<T> {
  renderSlide: (props: T, index: number) => React.ReactNode;
}

export type LargeImageBackgroundCarouselBlock = StreamfieldBlock<
  "large_image_background_hero_carousel",
  HeroCarouselCmsData<LargeImageBackgroundHeroCmsData>
>;

export const HeroCarousel = <T extends unknown>({ slides, renderSlide }: Props<T>) => {
  return (
    <CarouselProvider
      naturalSlideWidth={213}
      naturalSlideHeight={243}
      infinite={true}
      isIntrinsicHeight={true}
      visibleSlides={1}
      totalSlides={slides.length}
      className={styles.heroCarousel}
      step={1}
    >
      <Slider className="al-hero-carousel" trayTag="ul" classNameTray="">
        {slides.map((slide, index) => (
          <Slide key={slide.id} index={index} className="" tag="li">
            {renderSlide(slide.value, index)}
          </Slide>
        ))}
      </Slider>
      <PreviousSlideButton className={`${styles.buttonPrevious} al-hero-carousel__prev`} />
      <NextSlideButton className={`${styles.buttonNext} al-hero-carousel__next`} />
      <DotGroup className={`${styles.dotGroup} al-hero-carousel__dots`}></DotGroup>
    </CarouselProvider>
  );
};
