import { standardBreakpointBuilder } from "components/Flexihero";
import {
  HeroBreakpointSettings,
  TextAlignment,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";
import { CmsHero, CmsMedia, HeroMedia } from ".";

import heroStyles from "./hero-blocks.module.scss";

export interface LargeImageBackgroundHeroCmsData extends CmsHero {
  text_color: "light" | "dark";
  content_placement: "left" | "right" | "center";
  text_alignment: TextAlignment;
  mobile_media: CmsMedia;
}

export type LargeImageBackgroundBlock = StreamfieldBlock<
  "large_image_background_hero",
  LargeImageBackgroundHeroCmsData
>;

export const LargeImageBackgroundHero = (props: LargeImageBackgroundHeroCmsData) => {
  const contentAlignSecondary =
    props.content_placement === "left"
      ? "left/top"
      : props.content_placement === "center"
      ? "middle"
      : "right/bottom";

  const baseSettings: HeroBreakpointSettings = {
    backgroundColor: "transparent",
    backgroundColorPlacement: "none",
    contentAlignPrimary: "middle",
    contentAlignSecondary,
    contentWidth: "third",
    textColor: props.text_color === "light" ? "light" : "inherit",
    verticalMargin: "zero",
    heroPlacement: "background-full",
    mediaAspectRatio: "none",
    mediaPlacement: "background",
    textAlignment: props.text_alignment,
    heroDirection: "row",
  };

  const desktopSettings = {
    ...baseSettings,
    minHeight: 600,
    maxHeight: 600,
  };

  const desktopMedia = (
    <HeroMedia
      media={props.media}
      imageProps={{
        cldSrcSet: [1440, 2880, 4320],
        sizes: "100vw",
        loading: "eager",
      }}
      videoProps={{
        autoPlay: true,
        respectPreferReducedMotion: true,
        control: { visibility: "none" },
      }}
    />
  );

  const set = standardBreakpointBuilder({
    desktop: {
      content: props.content || "",
      media: props.media && desktopMedia,
      settings: desktopSettings,
    },
    tablet: {
      content: props.content || "",
      media: props.media && desktopMedia,
      settings: {
        ...desktopSettings,
        contentWidth: "custom",
        contentWidthCustom: "42%",
      },
      styles: {
        innerClassName: heroStyles.homeHeroTablet,
      },
    },
    mobile: {
      content: props.content || "",
      media: heroMobileImage(props.media, props.mobile_media),
      settings: {
        ...baseSettings,
        heroPlacement: "full",
        contentWidth: "full",
        contentAlignSecondary: "right/bottom",
        mediaAspectRatio: "8:5",
        mediaPlacement: "inline",
        textAlignment: "center",
        heroDirection: "column",
        textColor: "inherit",
      },
      styles: {
        contentWrapperClassName: heroStyles.homeHeroMobile,
      },
    },
  });
  return <HeroSet {...set} />;
};

/** Assumes that a desktop image will always exist but a mobile image will not */
const heroMobileImage = (media: CmsMedia, mobileMedia: CmsMedia) => {
  let mediaToUse = media;
  if (!!mobileMedia?.image || !!mobileMedia?.video) {
    mediaToUse = mobileMedia;
  }
  return (
    <HeroMedia
      media={mediaToUse}
      imageProps={{
        cldSrcSet: [375, 750, 1125, 1500],
        sizes: "100vw",
        loading: "eager",
      }}
      videoProps={{
        autoPlay: false,
        control: { visibility: "native" },
      }}
    />
  );
};
