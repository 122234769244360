import { EmtekPageProps } from "pages/[[...slug]]";
import { ValueProps, ValuePropsBlock } from "components/ValueProps";
import { TestimonialCards, TestimonialCardsBlock } from "components/Testimonials";
import Layout from "components/Layout";
import {
  HeroCarousel,
  LargeImageBackgroundCarouselBlock,
} from "components/HeroBlocks/HeroCarousel";
import { LargeImageBackgroundHero } from "components/HeroBlocks/LargeImageBackgroundHero";
import { ToolboxBlock, Toolbox } from "./Toolbox";

export type PageProps = EmtekPageProps<{
  body: HomePageBlock[];
}>;

type HomePageBlock =
  | ToolboxBlock
  | LargeImageBackgroundCarouselBlock
  | TestimonialCardsBlock
  | ValuePropsBlock;

const HomePage: React.FC<PageProps> = (props) => {
  return (
    <Layout
      metadata={props.wagtail}
      menus={props.menus}
      //  promo={props.promo}
    >
      {props.wagtail.body.map((block, index) => {
        switch (block.type) {
          case "large_image_background_hero_carousel":
            return (
              <HeroCarousel
                key={block.id}
                {...block.value}
                renderSlide={(value) => <LargeImageBackgroundHero {...value} />}
              />
            );
          case "testimonial_cards":
            return <TestimonialCards key={block.id} {...block.value} width="padded" />;
          case "value_props":
            return <ValueProps key={block.id} {...block.value} />;
          default:
            return <Toolbox key={block.id} block={block} isFirstBlock={index === 0} />;
        }
      })}
    </Layout>
  );
};

export default HomePage;
